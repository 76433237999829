

// Variables
// @import 'variables';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

$color-grey: #9599a1;
$color-lightgrey: #f7f5f3;
$color-darkgrey: rgb(79, 75, 75);
$color-blue: #15488a;

.dark-grey {
  color: $color-darkgrey
}
.light-grey {
  color: $color-lightgrey
}

.grey {
  color: $color-grey
}

html,
body {
  
  // overflow: auto !important; 
  // display: contents !important;
  font-family: 'Poppins';
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

}




body, html{
  margin: 0;
  padding: 0;
  margin-left: 13rem;
  margin-right: 13rem;
  // width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  // Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

footer {
  // background-color: $color-lightgrey;
  // margin-bottom: 100px;
  // display: flex;
}
.footer-grey {
  color: #626367;
}

body .font-light {
  font-weight: 300;
  p, div, span, h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }
}

body .font-regular {
  font-weight: 400;
  p, div, span, h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }
}


#printable_quote {
  display: none;
}

.company-statistics-container {
    background: url(/aboutus_stat_placeholder.jpg) no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    background-attachment: fixed;
    background-size: cover;
}

@mixin base-font-1() {
  font-size: 0.75rem; //12px
}
@mixin base-font-2() {
  font-size: 0.875rem; //14px
}
@mixin base-font-3() {
  font-size: 1.125rem; //18px
}
@mixin base-font-4() {
  font-size: 1.25rem; //20px
}
@mixin base-font-5() {
  font-size: 2.25rem //36px
}


.f-xs {
  font-size: 12px
}
.f-small {
  font-size: 14px
}
.f-medium {
  font-size: 16px;
}
.f-large {
  font-size: 22px;
 
}

body .f-size_1 {
  @include base-font-1;
}
body .f-size_2 {
  @include base-font-2;
}
body .f-size_3 {
  @include base-font-3;
}
body .f-size_4 {
  @include base-font-4;
}
body .f-size_5 {
  @include base-font-5;
}

h1 {
  // margin: 0;
}

.reset-list {
  list-style-type: disc;
  padding: revert;
}

.list-disc {
  list-style-type: disc;
}

.list-circle {
  list-style-type: circle;
}

.list-inside {
  list-style-position: inside;
}

.list-margin > li {
  margin-bottom: 0.5em;
}

// width

.mobile_view {
  display: none;
}

.desktop_view {
  display: block;
}


.font-bolder-swipe{
font-weight: bold;
}

body .margin-auto {
  margin-left: auto;
  margin-right: auto;
}

body .vertical-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.error {
  color: #ed2626 !important;
}
.warning {
  color: #ef950c;
}

.align-items-center{
  align-items: center;
}
.align-items-end {
  align-items: end;
}
.align-items-center{
  align-items: center;
}
.align-content-center {
  align-content: center;
}
.text-align-center{
  text-align:'center';
}

//end of width
.code-on-image {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
}

.slick-prev:before,
.slick-next:before {
 color: $color-grey !important;
}

// .slick-slide .slick-active{
//   border: 1px solid #000;
// }

.border-light {
  border: 1px solid #0000001f;
}

.tile-product-heading {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 48px;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  display: -webkit-box;
  font-weight: 500;
  font-size: 15px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.product-tile-pricing {
  display: flex;
  justify-content: center;
}

.product-variants {
      width: 50px;
      position: absolute;
      right: 0;
      top: 0;
}

.double-strike-out{
  text-decoration-style: double;
  text-decoration-line: line-through;
}

.strikethrough {
  text-decoration-line: line-through;
}

.hide-horizontal-overflow{
  // overflow-y: hidden;
  max-width: 100%;
  overflow-x: hidden;
}
// .carousel.thumbs-wrapper{
//   overflow-x: hidden;
// }

.justify-left{
  justify-content: left;
}
.justify-right{
  justify-content: right;
}
.flex-direction-row{
  flex-direction: row;
}
.fs-15{
  font-size: 15px;
}

.fs-18{
  font-size: 18px;
}
.fs-20{
  font-size: 20px;
}
.overflow-hidden{
  overflow: hidden;
}
.hover-underline{
    display: inline-block;
    color: var(--color-primary);
    text-decoration: none;
}

.primary-font-color{
  color: var(--color-primary);
}

.account-border-input:hover{
  border: 1px solid var(--color-primary) !important;
  border-radius: 4px;
}
.finish-width {
  width:auto;
  overflow-x: auto;
}

// ribbon start
.ribbon {
  position: absolute;
  right: 1px;
  top: 0px;
  z-index: 2;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#D41010 0%, #D41010 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
  /* z-index: 500000000; */
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #D41010;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #D41010;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #D41010;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #D41010;
} 

//ribbon ends



.hover-underline::after{
  content: '';
  margin-top: 2px;
  display: block;
  width: 0;
  height: 2px;
  background: var(--color-primary);
  transition: width .3s;
}

.hover-underline:hover::after{
  width: 100%;
} 


.hover-underline-product{
  display: inherit;
  color: var(--color-primary);
  text-decoration: none;
}
.hover-underline-product::after{
  content: '';
  margin-top: 2px;
  display: block;
  width: 0;
  height: 2px;
  background: var(--color-primary);
  transition: width .3s;
}
.hover-underline-product:hover::after{
  width: 100%;
} 

.cursor-default {
  cursor:auto !important;
}

.pointer {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-link {
  color: unset;
}


.mr-5 {
  margin-right: 20px;
}

.h-90 {
  height: 90%;
}

.text-vertical {
  display: flex;
  align-items: center;
}

/* container */
.package-core-grid {
    display: grid;
    grid-template-columns: minmax(180px, 225px) 1fr;
    // color: var(--color-secondary);
}

/* columns */
.package-core-grid > * {
    // padding:0.25rem;
}

.package-core-box {
  height: 240px;
  border-radius: 20px;
  // background-color: var(--color-secondary);
  border: 2px solid grey;
  transition: width .3s;
  padding-top: 5px;
  padding-bottom: 5px;
}

.package-core-box:hover {
  transition: width .3s;
}

.bg-secondary {
  background-color: var(--color-secondary);
}

.package-core-box-active {
  border: 1px solid var(--color-secondary);
}

.ucFirst {
  text-transform: capitalize;
}
//FONT
body .fw-bold {
  font-weight: 600;
}
body .fw-semi-bold {
  font-weight: 500;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}

.fs-30{
  font-size: 30px;
}

.display-none{
  display: none;
}

.color-grey{
  color: grey;
}
//END OF FONT
.position-absolute {
  position: absolute;
}

.position-inherit {
  position: inherit;
}

.position-relative {
  position: relative;
}
.position-fixed{
  position:fixed;
}
.bottom-0{
  bottom: 0;
}

.left-0{
  left: 0;
}


.image-responsive {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
}

.input-hover:hover {
  background: #A1BDD914;
}

.input-hover {
  display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    border: 2px solid transparent;
    border-radius: 3px;
    transition: background 0.2s;
    width: 100%;
    cursor: text;
}

.product-reviews {
  float:right;
  display: flex;
}

.variant-overflow { 
  display:flex;
  overflow: auto;
}

.default-card {
  padding: 24px;
}

.link {
  color: #0d6efd;
}

// .swiper-button-prev {
//   // padding: 50px;
// }
.swiper-button-next , .swiper-button-prev {
  // padding: 50px;
  display: flex;
  position: absolute;
  background-color: #fff;
  z-index: 10;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  height: 20px !important;
  width: 14px !important;
  border: 0px solid white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color:var(--color-primary);
  color:var(--color-primary-text);
  font-size: 16px;
}
.swiper-button-next:after , .swiper-button-prev:after {
  color:var(--color-primary-text);
  font-size: 16px;
}
.quote-increment{
  // padding: 50px;

  
  background-color: #fff;
  z-index: 1;
  
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20%;
  // margin: 1px;

  // padding-left: 10px;
  // padding-right: 10px;
  // padding-top: 7px;
  // padding-bottom: 7px;
  background-color:var(--color-primary);
  color:var(--color-primary-text);
  border: 1px solid var(--color-primary);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.demo-bg {
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.quote-increment2{
  // padding: 50px;

  
  background-color: #fff;
  z-index: 1;
  
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20%;
  // margin: 1px;

  // padding-left: 10px;
  // padding-right: 10px;
  // padding-top: 7px;
  // padding-bottom: 7px;
  background-color:var(--color-primary);
  color:var(--color-primary-text);
  border: 1px solid var(--color-primary);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

//product-page thumbnail start

.thumbnail .keen-slider__slide {
  font-size: 30px;
  margin-top: 10px;
  height: 100px;
 
}
.thumbnail .keen-slider__slide {
  cursor: pointer;

}
.thumbnail .keen-slider__slide.active {
  // display: inline-block;
  // color: var(--color-primary);
  // text-decoration: none;
  //  border: 3px solid var(--color-primary);
  //  width: fit-content;
  //  display: flex;
  border: 2px dashed black;
}

.thumbnail .keen-slider:not([data-keen-slider-disabled]){
  width: fit-content;
}

.slick-thumb li {
  width: 60px;
  height: 45px;
}

.slick-dots li {
  width:initial !important;
  height:initial !important;
}

.slick-dots {
  // display: flex !important;
}
// .slick-next {
//   right:-12px;
// }
// .slick-prev {
//   left:-12px;
// }
// .slick-list{
//   padding: 0px !important
// }

.slick-initialized{
  // padding-right: 12px;
  // padding-left: 8px;
  // padding-top: 0px;
  // padding-bottom: 0px;
  padding-right: 12px;
  padding-left: 8px;
  padding-top: 0px;
  padding-bottom: 0px;


  button.slick-arrow.slick-prev{
    left:-15px;
   color: #0d6efd;
  }
  button.slick-arrow.slick-next{
    right:-10px;
  }
}

// .slick-slider .thumbus {
//   padding: 0px;
// }

.thumbnail-slider-wrap {
 
  height: 85px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0px;
  padding-top: 0px;
  display: inline;
}
// .thumbnail-slider-wrap .slick-track .slick-slide {
//   text-align: center;
// }
// .thumbnail-slider-wrap .slick-track .slick-slide img {
//   width: 95%;
// }

.thumbnail-slider-wrap .slick-track .slick-current .slick-slide img{
  border: 2px solid var(--color-primary);
}

[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 200px;
  max-height: 100vh;
}

.navigation-wrapper {
  display: block;
  position: relative;
  min-width: 100%;
 max-width: 100%;
 max-height: 100%;
 min-height: 100%;
  // width: fit-content;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 38%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: var(--color-primary);
  border-radius: 50%;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #fff;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}




.arrow--left {
  left: 5px;
  padding-left: 3px;
  padding-right: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
  padding-left: 5px;
  padding-right: 3px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
//product-page thumbnail end

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/** *************** CHECKOUT ******************/


.title_counter_type {
    padding: 12px 20px 14px 18px;
    font-size: 1.5em;
    position: relative;
    background-color: $color-lightgrey;
}


//DELIVERY

//PAYMENT
.table-header {
  border: 1px solid #b1b0b0;
} 
/** *************** END OF CHECKOUT ******************/

.blockquote-p::before {
  content: "“";
  font-family: georgia;
  font-weight: bold;
  font-size: 30px;
  color: var(--color-primary)
}
.blockquote-p::after {
  content: "”";
    font-family: georgia;
    font-weight: bold;
    font-size: 30px;
    color: var(--color-primary)
}




//categories

.category-slider-information {
  position: absolute;
  padding: 5px;
  top: 40px;
  background-color: $color-lightgrey;
  opacity: 0.8;
  border-radius: 4px;
  margin-left: 50px;
  max-width: 750px;
  width: 75%;
}

//home
.swiper-fixed-height-200{
  height:200px;
}

//trustpilot widget
.hidden {
  display: none;
}
.tp-widget-loader,
.tp-widget-fallback {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
#fallback-logo {
  display: none;
}
.no-svg #fallback-logo {
  display: inline-block;
}
.tp-widget-wrapper {
  height: 100%;
  max-width: 750px;
  position: relative;
  margin: 0 auto;
}
.tp-stars .tp-star__canvas,
.tp-stars .tp-star__canvas--half {
  fill: #dcdce6;
}
.tp-stars .tp-star__shape {
  fill: #fff;
}
.tp-stars--1 .tp-star:nth-of-type(-n + 1) .tp-star__canvas,
.tp-stars--1 .tp-star:nth-of-type(-n + 1) .tp-star__canvas--half {
  fill: #ff3722;
}
.tp-stars--2 .tp-star:nth-of-type(-n + 2) .tp-star__canvas,
.tp-stars--2 .tp-star:nth-of-type(-n + 2) .tp-star__canvas--half {
  fill: #ff8622;
}
.tp-stars--3 .tp-star:nth-of-type(-n + 3) .tp-star__canvas,
.tp-stars--3 .tp-star:nth-of-type(-n + 3) .tp-star__canvas--half {
  fill: #ffce00;
}
.tp-stars--4 .tp-star:nth-of-type(-n + 4) .tp-star__canvas,
.tp-stars--4 .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
  fill: #73cf11;
}
.tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas,
.tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-stars--1--half .tp-star:nth-of-type(-n + 2) .tp-star__canvas--half {
  fill: #ff3722;
}
.tp-stars--2--half .tp-star:nth-of-type(-n + 3) .tp-star__canvas--half {
  fill: #ff8622;
}
.tp-stars--3--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
  fill: #ffce00;
}
.tp-stars--4--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas,
.tp-stars--4--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-stars--4--half .tp-star:nth-of-type(-n + 5) .tp-star__canvas--half {
  fill: #00b67a;
}
.tp-widget-loader,
.tp-widget-fallback {
  max-width: 50%;
  text-align: center;
  width: 400vh;
}
.tp-widget-loader svg,
.tp-widget-fallback svg {
  width: 100%;
}
@media screen and (min-width: 400px) {
  .tp-widget-loader,
  .tp-widget-fallback {
    max-width: 200px;
  }
}
.tp-widget-loader--loaded {
  opacity: 0;
}
.bold-underline {
  border-bottom: 1px solid rgba(25, 25, 25, 0.6);
}
.bold-underline:hover {
  border-color: #191919;
}
.dark {
  color: #fff;
}
.dark a {
  color: #fff;
}
.dark .bold-underline:not(.bold-underline--single-theme) {
  border-color: rgba(255, 255, 255, 0.6);
}
.dark .bold-underline:not(.bold-underline--single-theme):hover {
  border-color: #fff;
}
.dark .tp-logo__text {
  fill: #fff;
}
.dark .tp-widget-loader .tp-logo__text {
  fill: #191919;
}
svg {
  display: block;
  width: 100%;
}
#profile-link {
  color: #191919;
  text-decoration: none;
}
.tp-widget-wrapper {
  max-width: 255px;
}
.tp-widget-wrapper--placeholder {
  display: none;
}
.tp-widget-logo {
  margin: 0 0 8px;
  width: 55%;
}
.tp-widget-stars {
  margin: 0 0 8px;
  width: 94%;
}
.tp-widget-businessinfo {
  font-size: 11px;
  line-height: 1.2;
}
.tp-widget-businessinfo__trustscore,
.tp-widget-businessinfo__total {
  text-transform: none;
}
@media screen and (min-width: 200px) {
  .tp-widget-logo {
    max-width: 140.25px;
  }
  .tp-widget-businessinfo {
    font-size: 14px;
    max-width: 255px;
  }
}
@media screen and (min-width: 235px) {
  .tp-widget-businessinfo__trustscore,
  .tp-widget-businessinfo__total {
    display: inline-block;
    vertical-align: middle;
  }
  .tp-widget-businessinfo__trustscore::after {
    content: "|";
    margin: 0 2px;
  }
}
#trust-score {
  font-weight: 700;
}
#businessEntity-numberOfReviews-total {
  font-weight: 700;
}
#reviews-summary {
  font-family: "Arial", "sans-serif" !important;
  // font-size: 12px !important;
}
.preview-widget {
  -webkit-box-pack: center;
  justify-content: center;
  overflow: auto;
  // padding: 32px 0;
  width: 100%;
}
.preview-widget,
.configuration__preview__inner {
  -webkit-box-flex: 1;
  display: -webkit-box;
  display: flex;
  flex-grow: 1;
}

.preview-widget-inner {
  width: 100%;
}

.overflow-hidden{

  overflow: hidden;
}
.modal-background{
  background-color:  rgba(0,0,0,0.75);;
}
.preview-widget-background {
  -webkit-box-align: center;
  align-items: center;
  align-self: flex-start;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
  display: none;
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: 16px;
  position: relative;
}
.preview-widget .trustpilot-widget {
  -webkit-box-pack: center;
  display: -webkit-box;
  display: flex;
  justify-content: center;
  width: 100%;
}
.preview-widget {
  // padding: 43px 0px 40px 0px;
}
.testimonial_margin{
  margin-bottom: 20px;
}
@media only screen and (max-width: 780px) {
  .preview-widget {
    // padding: 20px 0px 32px 0px;
  }
  .testimonial_margin{
    margin-bottom: 0px !important;
  }
}

.search-desktop {
  width: 255px;
}

.light-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.box-shadow{
  box-shadow: 0px 7px 5px -5px rgb(0 0 0 / 0.3);
}
.box-shadow-card{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
//header
.top_header {
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 28999;
  text-align: center;
  background: var(--color-primary);
  height: 30px;
  line-height: 30px;
  color: white;
}
.mid_header{
  z-index: 28999;
  top: 30px;
  width: 100%;
  left: 0px;
  background-color: #f3f3f5;
  width: 100%;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #eae8e8;
  color: #797979;
}

.slick-dots {
  position: unset !important;
}

.request_callback_btn{
  background: transparent;
  border: 0px;
  color: var(--color-primary);
  font-weight: bold;
}
.brand_telephone_link{
  font-family: 13px !important;
  font-weight: bold !important;
}

.request_callback_icon{
  width: 19px;
  height: 19px;
  margin-bottom: -5px;
}

.brand-font{
  color: var(--color-primary);
}
.brand-background {
  background-color: var(--color-primary);
}
.brand-divider {
  border: 3px solid var(--color-primary);
}
.background-grey {
  background-color: #f9f9f9;
}

.brand-header{
  padding-top: 60px;
  margin: 0px auto;
  padding-top: 30px;
  width: 980px;
}
.header_search_field{
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border-color: var(--color-primary);
  border-width: 1px;
  border-style: solid;
  color: var(--color-primary);
}

.header_search_field:focus-visible{
  outline: none;
}
.header_search_btn{
  float: right;
  padding: 2px 10px 5px 10px;
  margin-top: 8px;
  font-size: 17px;
  background: var(--color-primary);
  color: white;
  cursor: pointer;
  border-color: var(--color-primary);
  border-width: 1px;
  border-style: solid;
}

.header_search_icon{
  color:white;
}

.navbar {
  overflow: hidden;
  background:var(--color-primary);
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown-icon{
  margin: -8px 0px;
}

.navbar-dropdown {
  float: left;
  overflow: hidden;
}

.navbar-dropdown .navbar-dropdown-item{
  font-size: 16px;  
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

// .navbar-dropdown:hover .navbar-dropdown-item 
.navbar a:hover,.navbar-dropdown-content a:hover,.navbar-subdropdown-content a:hover, .navbar-dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.10) !important;
}

.navbar-dropdown-content, .navbar-subdropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 2000;
  border: 1px solid $color-grey;
  margin-top:5px;
}

.navbar-dropdown-content a,.navbar-subdropdown-content a  {
  float: none;
  color: $color-grey;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.navbar-subdropdown-content{  
  margin-left: 213px;
  margin-top: -2px;

}
.navbar-dropdown:hover .navbar-dropdown-content {
  display: block;
}

.navbar-dropdown-content div:hover .navbar-subdropdown-content{
  display: block;
}
.navbar-dropdown-content div{
  list-style: none;
}

.subdropdown-icon{
  margin: -8px 4px;
}

.customer-service-arrow{
  width: 21px;
  height: 21px;
  margin-bottom: -3px;
  color: #088f64;
  margin-left:7px;
}
.header_quote_btn {
  background: transparent;
  border: 0;
  color: inherit;
  font-size: 16px;
}
.widget_icon{
  width: 50px;
  height:50px;
  background:var(--color-primary);
  color: white;
  cursor: pointer;
}
.widget_title{
  font-size: 1em;
  font-weight: bold;
}

//category filter and sorting
.left-center-align{
  font-size: 16px;
}

.uppercase {
  text-transform: uppercase;
}
.underline {
  text-decoration: underline;
}
.camel-case {
  text-transform: initial;
}
.lowercase {
  text-transform: lowercase;
}

.white {
  color: #fff;
}

.styled-bullets-primary > div > div > li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--color-primary); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1.5em; /* Also needed for space (tweak if needed) */

}

.quote-contact-red {
  background-color: #FFEEEE;
}
.quote-contact-green {
  background-color: #EEFFEE;
}

.quill-height .ql-editor {
  min-height: 338px;
}

.audit-modal .pswp img {
  object-fit: cover !important;
}

.pswp__img {
  object-fit: contain !important;
}

.boxed-padding {
  margin-left: auto;
  margin-right: auto;
  padding-left: 13rem;
  padding-right: 13rem;
  max-width: 1800px;
}
.boxed-padding-responsive{
  margin-left: auto;
  margin-right: auto;
  padding-left: 13rem;
  padding-right: 13rem;
  max-width: 1800px;
}

.boxed-padding .company-statistics-container {
  padding: 0 !important;
}

.hire_furniture_p {
    @include base-font-1;
}


body .cart-add {
  padding: 6px 16px;
  // @include base-font-1;
}

.category-tile-p {
  @include base-font-3;
}

body .masonry-title {
  @include base-font-5;
}

body .qty-input {
  // width: 56px;
}

.is-sticky {
  // position: fixed;
  // top: -8px;
  // z-index: 999;
  // animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

@media (max-width: 1600px) {
  body {
    .masonry-title {
      @include base-font-4;
    }
  }

}



@media (max-width: 1250px) {
    .hire_furniture_p {
      padding: 1rem;
      @include base-font-3;
    }
    .category-tile-p {
      @include base-font-1;
    }
    .category-tile > span {
      height: 155px !important;
    }
    body .qty-input {
      max-height: 15px;
    }
    body .cart-add {
      padding: 5px 10px;
      width: -webkit-fill-available;
    }
}

@media (max-width: 1400px) {
    .search-desktop {
      width: 135px;
    }
    body .qty-input {
      // width: 50px;
    }
}

.my-image {
  width: 'auto';
  height: 'auto';
}




//TODO: carousel will need reviewing as width is hard coded here, will affect other classes/carousels
body .carousel > .thumbs-wrapper {
  width:50px;
  overflow-x: scroll;
  min-width: 95%;
}



body .slick-track {
  margin-left: inherit;
  margin-right: inherit;
}


@media (max-width: 1669px) {
  .carousel-root {
    width:auto;
  }
  .boxed-padding {
    /* boxed-padding needs adjustment after mark is done with dsahbaord */
    // padding-left: 12.3rem;
    // padding-right: 12.3rem;
    padding-left: 9.8rem;
    padding-right: 9.8rem;
  }
  .boxed-padding-responsive {
    padding-left: 9.8rem;
    padding-right: 9.8rem;
  }

}
@media (max-width: 1563px) {
  .carousel-root {
    width:auto;
  }
  .boxed-padding {
    /* boxed-padding needs adjustment after mark is done with dsahbaord */
    // padding-left: 8.3rem;
    // padding-right: 8.3rem;
    padding-left: 9.8rem;
    padding-right: 9.8rem;
  }

}
@media (max-width: 1363px) {
  .carousel-root {
    width:auto;
  }
  .boxed-padding {
    /* boxed-padding needs adjustment after mark is done with dsahbaord */
    // padding-left: 8.3rem;
    // padding-right: 8.3rem;
    padding-left: 9.8rem;
    padding-right: 9.8rem;
  }

}


@media (max-width: 1200px) {
  .carousel-root {
    width:auto;
  }
  .boxed-padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .boxed-padding-responsive {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  footer {
    padding-bottom: 55px;
  }
}

/* Hide content on mobile devices */
.hide_on_mobile {
  display: none;
}
.hide_on_mobile_flex {
  display: none;
}

/* Hide content on desktop devices */
.hide_on_desktop {
  display: none;
}

/* Hide content on desktop devices */
.hide_on_desktop_flex {
  display: none;
}

/* Using media queries to specify when each class should take effect */

/* Assuming 768px as the breakpoint between mobile and desktop */
@media (min-width: 1200px) {
  .hide_on_mobile {
      display: block;
  }
  .hide_on_mobile_flex {
      display: flex;
  }

}

@media (max-width: 1200px) {
  .hide_on_desktop {
      display: block;
  }
  .hide_on_desktop_flex {
      display: flex;
  }
  .finish-width {
    width: 390px;
  }
  .package-core-grid {
    gap: 12px;
    display: grid;
    grid-template-columns: minmax(155px, 245px) 1fr;
  }
  .package-core-box {
    height: 240px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}


@media ( max-width: 2000px) {
  .arrow-top {
    top:32%
  }
}

@media ( max-width: 1669px) {
  .arrow-top {
    top:29%
  }
}


@media (max-width: 1500px) {
  .arrow-top {
    top:20%
  }
}


@media (max-width: 1300px) {
  .arrow-top {
    top:10%
  }
}

@media (max-width: 1200px) {
  .arrow-top {
    top:40%
  }
  .desktop_view {
    display: none;
  }
  .mobile_view {
    display: block;
  }
}

// .arrow-top{
//   top: 30%;
// }

// @media (max-width: 1200px) {
//   // .category-tile {
//   //   height: 220px !important;
//   // }
//   body {
//     .masonry-title {
//       @include base-font-2;
//     }

//   }

// }

// .container-div {
//   height: 450px;
//   width: auto;
// }

// .container-div > img {
//   width: auto;
//   height: auto;
// }


//inputs 
input[disabled], input[disabled]:hover { 
  background-color: #f7f7f7;
  cursor: not-allowed;
}

.c2a_mode1 {
  z-index: 1300 !important;
}

@keyframes flashRed {
  0% {
    background-color: #eb5b5b;
  }
  50% {
    background-color: #e0eceb; /* Original color */
  }
  100% {
    background-color: #eb5b5b;
  }
}

.product {
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s, height 0.5s;
}

.product.visible {
  opacity: 1;
  // height: auto;
}

.product.invisible {
  height: 0;
}

:not(:disabled).pulsate_inverted {
	animation: inverted_pulse 2.5s infinite;
	border:1px solid #088f64 !important;
}

/* styles.css */
.page-heading {
  font-size: 22px;
  margin: 20px 0 8px 0;
}

@media (min-width: 600px) { /* sm breakpoint */
  .page-heading {
    font-size: 26px;
  }
}

@media (min-width: 960px) { /* md breakpoint */
  .page-heading {
    font-size: 30px;
    margin: 28px 0 8px 0;
  }
}


@keyframes inverted_pulse {
	0% {
		background-color: #f9f9f9;
		color: #606366;
	}

	50% {
		background-color: #f9f9f9;
		color: #606366;
	}

	52% {
		background-color: #088f64;
		color:white;
	}

	100% {
		background-color: #f9f9f9;
		color: #606366;
	}
	
}